@import 'a3_variables.less';
@import 'dark_variables.less';

.dropzone { 
    border-width: 1px;
    border-style: dashed;
    text-align: center;  
    cursor: pointer; 
    position: relative; 
}

.file-uploader {
    position: relative;
    text-align: center;
    border-width: 1px;
    border-style: dashed;
    cursor: pointer; 
}

.file-uploader .action-area {
    position: absolute;
    background-color: transparent;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
   
.dropzone:hover,
.dz-drag-hover,
.file-uploader:hover,
.file-uploader.drag-over {
    border-style: solid; 
}

.dropzone .progress { 
    position: absolute; 
    bottom: 0; 
    margin:0; 
    width:100%;
    height:100%;
    box-shadow:none; 
    background-color:transparent; 
    opacity: .5;
}

.dz-click-area { 
    width: 100%; 
    height: 100%; 
    position: absolute; 
    top: 0; 
    left: 0;
}

/* sizes */
.dropzone.thin,
.file-uploader { 
    min-height: 50px; 
}

/* Hide some default stuff */
.dropzone .dz-message { 
    display:none; 
}

.dz-preview {
    display:none;
}

.dropzone > p,
.file-uploader p { 
    margin: 5px; 
}

body .themed.silver {
    .dropzone { 
        border: @orange 1px dashed; 
        color: #666; 
        background-color:#fff; 
        text-align:center; 
        background-position:center; 
        background-repeat:no-repeat;
    }

    .file-uploader {
        border: @orange 1px dashed; 
        color: #666; 
        background-color:#fff; 
        text-align:center; 
        background-position:center; 
        background-repeat:no-repeat;
    }
    
    .dz-drag-hover,
    .file-uploader.drag-over {
        border-style: solid; 
        border-color: darkseagreen; 
        background-color:darkseagreen; 
        color:green;
    }

    .file-uploader.invalid {
        border-color: red;
        color: darkred;
        background-color: lightpink;
    }
}

body .themed.a3 {
    .dropzone { 
        border: @orange 1px dashed; 
        background-color:@white; 
        color: #666; 
        text-align:center; 
        background-position:center; 
        background-repeat:no-repeat; 
    }
    
    .file-uploader {
        border: @orange 1px dashed; 
        background-color:@white; 
        color: #666; 
        text-align:center; 
        background-position:center; 
        background-repeat:no-repeat; 
    }
    
    .dz-drag-hover,
    .file-uploader.drag-over {
        border-style: solid; 
        border-color: darkseagreen; 
        background-color:darkseagreen; 
        color:green;
    }

    .file-uploader.invalid {
        border-color: red;
        color: darkred;
        background-color: lightpink;
    }
}

body .themed.dark {
    .dropzone { 
        border-color: @dark_dropzoneBorderColor; 
    }
    
    .file-uploader {
        border-color: @dark_dropzoneBorderColor;         
    }
        
    .dz-drag-hover,
    .file-uploader.drag-over {
        border-color: @dark_positive; 
        background-color:darkseagreen; 
        color: @dark_gray2;
    }

    .file-uploader.invalid {
        border-color: @dark_negative;
        color: darkred;
        background-color: lightpink;
    }
}