@import 'a3_variables.less';

body .themed {
    
    /* uib-dropdown menu */
    .dropdown:not(.flex) {
        display: inline-block;
    }

    .dropdown.actions.v-center {
        display: flex;
    }
    
    .dropdown-menu { 
        border-radius: @baseBorderRadius;
        -webkit-box-shadow:0 2px 6px rgba(0, 0, 0, .175);
        box-shadow:0 2px 6px rgba(0, 0, 0, .175);
        padding: 5px 0;
     }
    
    .scrollbar.dropdown-menu ul {
        padding: 0;
    }

    .scrollbar.dropdown-menu ul {
        list-style: none;
        margin-bottom: 0;
    }

    .scrollbar.dropdown-menu { 
        max-height: 325px;
    }

    .dropdown-menu ul {
        list-style: none;
        margin-bottom: 0;
        padding: 0;
    }

    .dropdown-menu > li > a,
    .dropdown-menu > .scroller li > a,
    .dropdown-menu .list-group > li > a {
        display: block;
        clear: both;
        line-height: 1.42857143;
        white-space: nowrap;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently */
    }

    .dropdown-menu > li > a .grab-handle,
    .dropdown-menu > .scroller li > a .grab-handle,
    .dropdown-menu .list-group > li > a .grab-handle {
        cursor: move;
    }

    .scrollbar.dropdown-menu ul > li,
    .dropdown-menu > li,
    .dropdown-menu > .scroller li,
    .dropdown-menu > ul > .list-group > li {
        white-space: nowrap;
    }
    
    .scrollbar.dropdown-menu ul > li:not(.button-row) > a,
    .scrollbar.dropdown-menu ul > li:not(.button-row) > button,
    .dropdown-menu > li:not(.button-row) > a, 
    .dropdown-menu > li:not(.button-row) > button,
    .dropdown-menu > .scroller li:not(.button-row) > a,
    .dropdown-menu > .scroller li:not(.button-row) > span,
    .dropdown-menu > .scroller li:not(.button-row) > button,
    .dropdown-menu > .list-group > li:not(.button-row) > a, 
    .dropdown-menu > .list-group > li:not(.button-row) > button { 
        padding: 5px 10px 5px 10px;
        background-color: @white;
        color: @primaryFontColor;
        text-decoration: none;
        text-align: left;
        border-right: 5px solid;
        box-shadow:none;
        width:100%;
        font-weight:normal;
        font-size:14px;
        height:30px;
    }

    .dropdown-menu > li:not(.button-row) > a > i.clickable, 
    .dropdown-menu > li:not(.button-row) > button > i.clickable,
    .dropdown-menu > .scroller li:not(.button-row) > a > i.clickable, 
    .dropdown-menu > .scroller li:not(.button-row) > button > i.clickable,
    .dropdown-menu > .list-group > li:not(.button-row) > a i.clickable, 
    .dropdown-menu > .list-group > li:not(.button-row) > button > i.clickable { 
        margin-right: 0;
        padding-right: 20px;
        margin-left: -10px;
        padding-left: 13px;
    }

    .scrollbar.sortable.dropdown-menu ul > li:not(.button-row) > button,
    .scrollbar.sortable.dropdown-menu ul > li:not(.button-row) > a,
    .sortable.dropdown-menu > li:not(.button-row) > button,
    .sortable.dropdown-menu > li:not(.button-row) > a,
    .sortable.dropdown-menu > .scroller li:not(.button-row) > button,
    .sortable.dropdown-menu > .scroller li:not(.button-row) > a,
    .sortable.dropdown-menu > .list-group > li:not(.button-row) > button,
    .sortable.dropdown-menu > .list-group > li:not(.button-row) > a {
        width: calc(100% - 27px);
    }
    .dropdown .scrollbar.dropdown-menu ul li.button-row a,
    .dropdown .dropdown-menu li.button-row a {
        padding: 0;
    }
    
    .dropdown .scrollbar .dropdown-menu ul li.button-row a:hover,
    .dropdown .dropdown-menu li.button-row a:hover {
        background-color: transparent;
    }
    
    .scrollbar.dropdown-menu ul > li:not(.button-row) > a > i, 
    .scrollbar.dropdown-menu ul > li:not(.button-row) > button > i,
    .dropdown-menu > li:not(.button-row) > a > i, 
    .dropdown-menu > li:not(.button-row) > button > i,
    .dropdown-menu > .scroller li:not(.button-row) > a > i, 
    .dropdown-menu > .scroller li:not(.button-row) > button > i,
    .dropdown-menu > .list-group > li:not(.button-row) > a > i, 
    .dropdown-menu > .list-group > li:not(.button-row) > button > i  { 
        margin-right: 10px;
        width: 10px;
        display: inline-block;
     }
    
    .scrollbar.dropdown-menu ul > li:not(.button-row) > a:hover, 
    .scrollbar.dropdown-menu ul > li:not(.button-row) > button:hover,
    .dropdown-menu > li:not(.button-row) > a:hover, 
    .dropdown-menu > li:not(.button-row) > button:hover,
    .dropdown.scrollable .dropdown-menu > .scroller li:not(.button-row) > a:hover, 
    .dropdown.scrollable .dropdown-menu > .scroller li:not(.button-row) > button:hover,
    .dropdown-menu > .list-group > li:not(.button-row) > a:hover, 
    .dropdown-menu > .list-group > li:not(.button-row) > button:hover  { 
        border-right: 5px solid @orange;
        color: @orange;
        background-color: @charcoal;
        border-color: #ffa400;
        border-radius: 0;
    }
    
    .scrollbar.dropdown-menu ul > li:not(.button-row) > a span,
    .scrollbar.dropdown-menu ul > li:not(.button-row) > button span,
    .dropdown-menu > li:not(.button-row) > a span,
    .dropdown-menu > li:not(.button-row) > button span,
    .dropdown-menu > .scroller li:not(.button-row) > a span,
    .dropdown-menu > .scroller li:not(.button-row) > button span,
    .dropdown-menu > .list-group > li:not(.button-row) > a span,
    .dropdown-menu > .list-group > li:not(.button-row) > button span {
        margin-left:0;
    }

    .dropdown-menu .divider {
        margin: 5px 8px;
    }
    
    [dropdown] a:hover {
        text-decoration:none;
    }
    
    .scrollbar.dropdown-menu ul > li.input-field-row,
    .dropdown-menu > li.input-field-row,
    .dropdown-menu > .scroller li.input-field-row,
    .dropdown-menu ul > li.input-field-row {
        margin: 4px 8px 12px 8px;
    }
    
    .dropdown-menu .input-field-row .field-wrapper {
        margin-top: unset;
        flex: 1;
    }
    
    .dropdown-menu .input-field-row .between-label {
        margin: auto 8px;
        font-size: 75%;
    }
    
    .dropdown-menu .input-field-row .field-wrapper +
    .dropdown-menu .input-field-row .field-wrapper {
        margin-left: 8px;
    }
    
    .dropdown-menu .button-row {
        display: flex;
        margin-top: 4px;
        padding: 8px;
        margin-left: auto;
        max-width: 260px;
    }
    
    .dropdown-menu .button-row button,
    .dropdown-menu .button-row a {
        flex: 1;
    }
    
    .dropdown-menu .input-field-row input[aa-field-group-strategy=date],
    .dropdown-menu .input-field-row input[aa-field-group-strategy=dateString] {
        text-align: center;
    }

    .dropdown-menu .query-builder {
        min-width: 400px;
        display: block;
        padding: 20px;
    }

    .dropdown-menu .view-selection-button {
        display: flex !important;
    }

    .dropdown-menu .view-selection-button .view-shared-icon:not(.active) {
        opacity: 0;
    }

    .dropdown-menu .view-selection-button:hover .view-shared-icon {
        opacity: 1;
    }

    .dropdown.column-selection-dropdown ul .scroller {
        min-width: 250px;
        max-height: 585px !important;
    }

    .dropdown.scrollable .dropdown-menu {
        padding-bottom: 0;
    }

    .dropdown .dropdown-menu .more-record-indicator {
        padding-top: 3px;
        text-align: center;
        font-size: 75%;
        opacity: 0;
    }

    .dropdown .dropdown-menu .more-record-indicator.active {
        opacity: .5;
    }

    .dropdown .dropdown-menu > li.dropdown-search-field {
        padding: 10px;
    }

    .dropdown .dropdown-menu .dropdown-search-field .field-wrapper {
        margin-top: 0;
    }
}


body .themed.a3 {
    .dropdown-menu .view-selection-button .view-shared-icon {
        color: @darkGray;
    }

    .dropdown-menu .view-selection-button .view-shared-icon.active {
        color: @green;
    }

    .dropdown .dropdown-menu > li, 
    .dropdown .dropdown-menu > li a, 
    .dropdown .dropdown-menu > li button,
    .dropdown .dropdown-menu > li > i, 
    .dropdown .dropdown-menu > li > i,
    .dropdown.scrollable .dropdown-menu  .scroller li, 
    .dropdown.scrollable .dropdown-menu  .scroller li a, 
    .dropdown.scrollable .dropdown-menu  .scroller li span,
    .dropdown .dropdown-menu .list-group li, 
    .dropdown .dropdown-menu .list-group li a { 
        color: #333; 
        background-color: transparent; 
        border-top:none; 
        border-left:none; 
        border-bottom:none; 
        border-color: transparent;
    }

    .dropdown .dropdown-menu > li a:hover, 
    .dropdown .dropdown-menu > li button:hover,
    .dropdown.scrollable .dropdown-menu  .scroller li a:hover ,
    .dropdown .dropdown-menu .list-group li a:hover { 
        color: @orange; 
        border-color: @orange; 
    }

    .dropdown .dropdown-menu > li.divider,
    .dropdown .dropdown-menu > .scroller li.divider { 
        background-color: #E5E5E5; 
    }
}
